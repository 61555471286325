
import React, { useState } from 'react';
import { Link, navigate, graphql } from 'gatsby'
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Container from '@material-ui/core/Container';
import { Carousel } from 'react-bootstrap';
import SEO from '../../components/seo';

import './requesterlanding.scss'
import homebanner from '../../images/landing/homebannerbg.jpg';
import Play from '../../images/icons/Play.png';
import PlayStore from '../../images/playstore.png';
import AppStore from '../../images/appstore.png';
import transparency from '../../images/landing/transparency.svg';
import quality from '../../images/landing/quality.svg';
import unbiaseddata from '../../images/landing/unbiased-data.svg';
import satisfaction from '../../images/landing/satisfaction.svg';
import money from '../../images/landing/money.svg';
import bgcircle from '../../images/landing/bgcircle.svg';
import ImageAnnotate from '../../images/landing/photo.svg';
import useFirestore from '../../modules/flamelink-module';
import _ from 'lodash';
import { WORKFORCE_APP_STORE, WORKFORCE_WEBSITE, WORKFORCE_PLAY_STORE, UNBIASED_VIDEO } from '../../config/constants';
import { MAIN_DATAANNOTATION, MAIN_USECASES, MAIN_DATACOLLECTION } from '../../config/routes';
import Partner1 from '../../images/landing/partner1.png';
import Partner2 from '../../images/landing/partner2.png';
import Partner3 from '../../images/landing/partner3.png';
import Partner4 from '../../images/landing/partner4.png';
import Partner5 from '../../images/landing/partner5.png';
import bannerImg1 from '../../images/landing/bannerImg1.png';
import bannerImg2 from '../../images/landing/bannerImg2.png';
import bannerImg3 from '../../images/landing/bannerImg3.png';
import bannerImg4 from '../../images/landing/bannerImg4.png';
import bannerImg5 from '../../images/landing/bannerImg5.png';
import bannerImg6 from '../../images/landing/bannerImg6.png';
import ContactModal from '../../components/ContactModal';


export default function RequesterLanding({ data }) {
    const docs = useFirestore('fl_content', 'requesterLanding');
    const [activeTypes, setActiveTypes] = useState(0);
    const [openVideo, setOpenVideo] = useState(false);

    const onHandleService = async (data, index) => {
        setActiveTypes(index);
    };

    const redirectdataannotation = (event) => {
        navigate(MAIN_DATAANNOTATION, { types: event });
    }

    const handleOpenVideo = () => {
        setOpenVideo(true);
    };

    const handleCloseVideo = () => {
        setOpenVideo(false);
    };

    return (
        <div className="home-cont">
            <SEO title="Requester" description="" />
            <ContactModal open={openVideo} handleClose={handleCloseVideo} source={UNBIASED_VIDEO} />
            <section className="main-banner">
                <img src={homebanner} alt="Home Banner" className="homeBannerImg" />
                <Container>
                    <div className="bannerCont requestorBanner">
                        <Grid container>
                            <Grid item xs={12} sm={12} md={6} >
                                <h2>Transparent and<br />Trustworthy<br />Data platform for AI</h2>
                                <Grid container className="btn-conts">
                                    <Button onClick={handleOpenVideo} target="_blank" className="btn-pink">
                                        <img src={Play} alt="play" width="20" />  What’s unbiased?
                                        </Button>
                                    <Link to={MAIN_USECASES}>
                                        <Button className="btn-gray">EXPLORE USE CASES</Button>
                                    </Link>
                                </Grid>
                            </Grid>
                            <Grid item xs={12} sm={12} md={6} >
                                <div className="bannerImg">
                                    <Carousel className="caroselSlider">
                                        <Carousel.Item>
                                            <div className="txts">
                                                <ul>
                                                    <li>
                                                        <p>Government Sector</p>
                                                        <div className="imgBox">
                                                            <img src={bannerImg1} alt="Government Sector" />
                                                        </div>
                                                    </li>
                                                    <li>
                                                        <p>Automotive</p>
                                                        <div className="imgBox">
                                                            <img src={bannerImg2} alt="Automotive" />
                                                        </div>
                                                    </li>
                                                    <li>
                                                        <p>Robotics</p>
                                                        <div className="imgBox">
                                                            <img src={bannerImg3} alt="Robotics" />
                                                        </div>
                                                    </li>
                                                </ul>
                                            </div>
                                        </Carousel.Item>
                                        <Carousel.Item>
                                            <div className="txts">
                                                <ul>
                                                    <li>
                                                        <p>Health Care</p>
                                                        <div className="imgBox">
                                                            <img src={bannerImg4} alt="Health Care" />
                                                        </div>
                                                    </li>
                                                    <li>
                                                        <p>Retail</p>
                                                        <div className="imgBox">
                                                            <img src={bannerImg5} alt="Retail" />
                                                        </div>
                                                    </li>
                                                    <li>
                                                        <p>Financial Services</p>
                                                        <div className="mgBox">
                                                            <img src={bannerImg6} alt="Financial Services" />
                                                        </div>
                                                    </li>
                                                </ul>
                                            </div>
                                        </Carousel.Item>
                                    </Carousel>
                                </div>
                            </Grid>
                        </Grid>
                    </div>

                </Container>
            </section>
            <div className="col-xs-12 col-sm-6">

            </div>

            <section className="data-annotation">
                <Container>
                    <h6>SERVICES</h6>
                    <h2>DATA ANNOTATION </h2>
                    <h2>KEEPING HUMANS IN THE LOOP</h2>
                    <Grid container className="mainwrap">
                        <Grid item xs={12} sm={12} md={4}>
                            <div className="container">
                                <div className="dataAnnotate">
                                    <div className="serviceTxt">
                                        {
                                            !_.isEmpty(docs) && docs[0].hasOwnProperty('serviceone')
                                            && docs[0].serviceone.hasOwnProperty('types') && docs[0].serviceone.types.map((val, index) => {
                                                return (
                                                    <div key={index}>
                                                        {
                                                            activeTypes === index ?
                                                                <div className="sBox" key={index}>
                                                                    <div className="ImgCont">
                                                                        <span className="line"></span>
                                                                        <a href tabindex="0" className={index === 0 ? "c1" : index === 1 ? "c2" : index === 2 ? "c3" : index === 3 ? "c4" : index === 4 ? "c2" : index === 5 ? "c3" : "c1"}><img className="imgIcon" src={ImageAnnotate} alt="ImageAnnotate" width="14" /></a>
                                                                    </div>
                                                                    <div className="sBoxDta">
                                                                        <h5 onClick={() => onHandleService('image', index)}>{val.title}</h5>
                                                                        <p>{val.description}</p>
                                                                        <button className="disablePointer"
                                                                            onClick={() => redirectdataannotation('Image')}
                                                                        >LEARN MORE</button>
                                                                    </div>
                                                                </div> :
                                                                <div className="sBox" key={index}>
                                                                    <div className="ImgCont">
                                                                        <span className="line"></span>
                                                                        <a href tabindex="0" className={index === 0 ? "c1" : index === 1 ? "c2" : index === 2 ? "c3" : index === 3 ? "c4" : index === 4 ? "c2" : index === 5 ? "c3" : "c1"} onClick={() => onHandleService('image', index)}><img className="imgIcon" src={ImageAnnotate} alt="ImageAnnotate" width="14" /></a>
                                                                    </div>
                                                                    <div className="sBoxDta">
                                                                        <h5 onClick={() => onHandleService('image', index)}>{val.title}</h5>
                                                                    </div>
                                                                </div>
                                                        }
                                                    </div>
                                                )
                                            })
                                        }
                                    </div>
                                </div>
                            </div>
                        </Grid>
                        <Grid item xs={12} sm={12} md={8} className="collection-cont">
                            <div className="dtImg">
                                {!_.isEmpty(docs) && docs[0].hasOwnProperty('serviceone')
                                    && docs[0].serviceone.hasOwnProperty('types') &&
                                    <img src={docs[0].serviceone.types[activeTypes].imagelink} alt="Data Annotation Images" />
                                }
                            </div>
                        </Grid>
                    </Grid>
                </Container>

            </section>

            <section className="data-collection">
                <Container>
                    {
                        !_.isEmpty(docs) && docs[0].hasOwnProperty('servicetwo') &&
                        <Grid container >
                            <Grid item xs={12} sm={12} md={6}>
                                <img src={docs[0].servicetwo.imagelink} className="service-banner" alt="Service" />
                            </Grid>
                            <Grid item xs={12} sm={12} md={6} className="collection-cont">
                                <h6>SERVICES</h6>
                                <h2>GROUND TRUTH<br />DATA COLLECTION</h2>
                                <p>{docs[0].servicetwo.description}</p>
                                <p>{docs[0].servicetwo.descriptiontwo}</p>
                                <Link className="dataC" to={MAIN_DATACOLLECTION}>
                                    <Button className="learnmore">LEARN MORE</Button>
                                </Link>
                            </Grid>
                        </Grid>
                    }
                </Container>
            </section>


            <section className="data-marketplace">
                <Container>
                    <Grid container >
                        {
                            !_.isEmpty(docs) && docs[0].hasOwnProperty('servicethree') &&
                            <Grid item xs={12} sm={12} md={6} className="marketplace-cont">
                                <h6>SERVICES</h6>
                                <h2>{docs[0].servicethree.title}</h2>
                                <p>{docs[0].servicethree.description}</p>
                                <p>{docs[0].servicethree.descriptiontwo}</p>
                                <div className="btLink">
                                    <Button href={process.env.GATSBY_APP_MARKETPLACE} className="learnmore">EXPLORE MARKETPLACE</Button>
                                </div>
                            </Grid>
                        }
                        <Grid item xs={12} sm={12} md={6}>
                            <div className="bannerImg">
                                <Carousel className="caroselSlider2">
                                    <Carousel.Item>
                                        <div className="txts">
                                            <ul>
                                                <li>
                                                    <div className="bxRed bx">
                                                        <p>ROAD CONDITIONS</p>
                                                    </div>
                                                </li>
                                                <li>
                                                    <div className="bxYellow bx">
                                                        <p>SENTIMENT ANALYSIS</p>
                                                    </div>
                                                </li>
                                                <li>
                                                    <div className="bxRed bx">
                                                        <p>AUTONOMOUS VEHICLES</p>
                                                    </div>
                                                </li>
                                            </ul>
                                        </div>
                                    </Carousel.Item>
                                    <Carousel.Item>
                                        <div className="txts">
                                            <ul>
                                                <li>
                                                    <div className="bxRed bx">
                                                        <p>ROAD CONDITIONS</p>
                                                    </div>
                                                </li>
                                                <li>
                                                    <div className="bxYellow bx">
                                                        <p>SENTIMENT ANALYSIS</p>
                                                    </div>
                                                </li>
                                                <li>
                                                    <div className="bxRed bx">
                                                        <p>AUTONOMOUS VEHICLES</p>
                                                    </div>
                                                </li>
                                            </ul>
                                        </div>
                                    </Carousel.Item>
                                </Carousel>


                            </div>
                        </Grid>
                    </Grid>
                </Container>
            </section>


            <section className="gwork-force">
                <img src={!_.isEmpty(docs) ? docs[0].middlebannerBg : ""} alt="Background" className="gwork-force-bg" />
                <Grid className="gbox-cont" container>
                    <Grid className="box" container>
                        <h3>{!_.isEmpty(docs) && docs[0].bannertitle}</h3>
                        <p>{!_.isEmpty(docs) && docs[0].bannerdescription}</p>
                        <div className="btLink">
                            <Button href={process.env.GATSBY_APP_MARKETPLACE} target="_blank" className="explore-btn">EXPLORE UNBIASED WORKFORCE</Button>
                        </div>
                        <Grid className="mobi-app-icons" container spacing={10} justify="center" >
                            <Button href={WORKFORCE_PLAY_STORE} target="_blank"><img src={PlayStore} alt="Play Store" height="42" /></Button>
                            <Button href={WORKFORCE_APP_STORE} target="_blank"><img src={AppStore} alt="App Store" height="42" /></Button>
                        </Grid>
                    </Grid>
                </Grid>
            </section>


            <section className="whyChoose">
                <img src={bgcircle} alt="Background" className="bgCircle" />
                <section container="true" className="why-choose-cont">
                    <h2>Why choose unbiased?</h2>

                    <div className="circleContLarge">
                        <div className="cCont">
                            <div className="circle">
                                <img src={transparency} alt="Transparency" width="36" />
                            </div>
                            <h6>TRANSPARENCY</h6>
                        </div>
                        <div className="cCont">
                            <div className="circle">
                                <img src={quality} alt="Quality Assurance" width="36" />
                            </div>
                            <h6>QUALITY ASSURANCE</h6>
                        </div>
                        <div className="cCont">
                            <div className="circle">
                                <img src={unbiaseddata} alt="Unbiased Data" width="34" />
                            </div>
                            <h6>UNBIASED DATA</h6>
                        </div>
                    </div>

                    <div className="circleContSmall">
                        <div className="cCont">
                            <div className="circle">
                                <img src={satisfaction} alt="Worker Satisfaction" width="36" />
                            </div>
                            <h6>WORKER SATISFACTION</h6>
                        </div>
                        <div className="cCont">
                            <div className="circle">
                                <img src={money} alt="Spam Free Accounts" width="36" />
                            </div>
                            <h6>SPAM FREE ACCOUNTS</h6>
                        </div>
                    </div>


                    <Grid container justify="center" className="btn-cont">
                        <Button onClick={handleOpenVideo} target="_blank" className="btn-solid"><img src={Play} alt="play" width="20" /> What’s unbiased?</Button>
                        <Button className="btn-bordered">LEARN MORE</Button>
                    </Grid>
                </section>
                <Grid container className="partnership" direction="column" alignItems="center">
                    <h2>WE FORM LONG TERM PARTNERSHIP</h2>
                    <div className="partnersList">
                        <ul>
                            <li>
                                <Link to="#"><img src={Partner1} alt="Partners" width="60" /></Link>
                            </li>
                            <li>
                                <Link to="#"><img src={Partner2} alt="Partners" width="140" /></Link>
                            </li>
                            <li>
                                <Link to="#"><img src={Partner3} alt="Partners" width="140" /></Link>
                            </li>
                            <li>
                                <Link to="#"><img src={Partner4} alt="Partners" width="70" /></Link>
                            </li>
                            <li>
                                <Link to="#"><img src={Partner5} alt="Partners" width="80" /></Link>
                            </li>
                        </ul>
                        <Button>JOIN OUR HAPPY PARTNERS</Button>
                    </div>
                </Grid>
            </section>

            <section className="happy-users">
                <img src={(!_.isEmpty(docs) && docs[0].hasOwnProperty('bottombanner')) ? docs[0].bottombanner.bottombannerBg : ""} alt="Background" className="happy-user-bg" />
                {!_.isEmpty(docs) && docs[0].hasOwnProperty('bottombanner') &&
                    <Grid container direction="column" alignItems="center" justify="center" className="formCont">
                        <h2>{docs[0].bottombanner.bannertitle}</h2>
                        <Button onClick={handleOpenVideo} target="_blank">
                            <img src={Play} alt="play" width="20" />
                        What’s unbiased?
                    </Button>
                    </Grid>
                }
            </section>



        </div >

    );
}

export const query = graphql`
    query {
      partnerImageOne: file(relativePath: { eq: "landing/partner1.png" }) {
        childImageSharp {
          fluid(maxWidth: 300) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `
